<template>
  <div class="dutyAddEdit" v-if="isDio">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="类型:" prop="menuType">
            <el-radio-group v-model="inform.menuType">
              <el-radio label="DIR">目录</el-radio>
              <el-radio label="PAGE">菜单</el-radio>
              <el-radio label="BUTTON">按钮</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="菜单编码:" prop="menuCode">
            <el-input type="text" v-model="inform.menuCode" placeholder="请输入菜单编码"></el-input>
          </el-form-item>
          <el-form-item label="菜单名称:" prop="menuName">
            <el-input type="text" v-model="inform.menuName" placeholder="请输入菜单名称"></el-input>
          </el-form-item>
          <el-form-item label="上级菜单:" prop="parentName">
            <el-popover
              ref="menuListPopover"
              placement="bottom-start" 
              popper-class="parentPop"
              :width="200"
              trigger="click">
              <el-tree
                :data="menuList"
                :props="cascProps"
                node-key="id"
                ref="menuListTree"
                @current-change="treeChange"
                :current-node-key="inform.parentId"
                :highlight-current="true"
                :expand-on-click-node="false">
              </el-tree>
              <el-input type="text" slot="reference"  v-model="inform.parentName" :readonly="true" placeholder="点击选择上级菜单" class="menu-list__input"></el-input>
              
            </el-popover>
            <!-- <el-cascader ref="cascader" v-model="inform.parentId" :options="menuList" :props="cascProps" :show-all-levels="false" clearable @change="selectName"></el-cascader> -->
          </el-form-item>
          <el-form-item label="菜单路由:" v-if="inform.menuType == 'PAGE'" prop="menuUrl">
            <el-input type="text" v-model="inform.menuUrl"></el-input>
          </el-form-item>
          <el-form-item label="排序号:" prop="idx" v-if="inform.menuType !== 'BUTTON'">
            <el-input-number :precision='0' v-model="inform.idx" controls-position="right" :min="1" placeholder="排序号"></el-input-number>
          </el-form-item>
          <el-form-item label="菜单图标:" prop="iconUrl" v-if="inform.menuType !== 'BUTTON'">
            <div class="formItem">
              <el-popover placement="bottom" :width="300" trigger="click" popper-class="popMenu">
                <el-input type="text" slot="reference" v-model="inform.iconUrl" placeholder="自定义图标名称"></el-input>
                
                <div class="menu_iconDiv">
                  <el-button :class="{'active':item == inform.iconUrl}" size="small" v-for="item in iconList" :key="item" :icon="item" @click="checkIcon(item)">
                    <!-- <i class="icon" :class="item" v-if="item.includes('icon-')"></i> -->
                  </el-button>
                </div>
              </el-popover>
              <i :class="inform.iconUrl" class="showIcon" style="font-size:20px;"></i>
              <!-- <i class="showIcon icon" :class="inform.iconUrl" v-if="inform.iconUrl?.includes('icon-')"></i>
              <el-icon :size="20" v-if="inform.iconUrl && inform.iconUrl?.includes('icon-') == false">
                <component class="showIcon" :is="inform.iconUrl"></component>
              </el-icon> -->
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
var vm;
export default {
  data() {
    return {

      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',//id
        appId:'',
        menuCode:'',//编码
        menuName:'',//名称
        parentId:'',//父级ID
        parentName:'',//父级名称
        menuType:'PAGE',//类型：DIR目录，PAGE菜单，BUTTON按钮
        menuUrl:'',//菜单地址
        idx:1,//排序号
        iconUrl:'',//图标
      },

      //菜单树形数据
      menuList :[{
        id: '0',
        menuName: '一级菜单',
        childPageMenus: []
      }],
      //菜单图标数据
      iconList :['el-icon-s-tools','el-icon-s-help','el-icon-s-platform','el-icon-message-solid','el-icon-s-operation',
                'el-icon-s-data','el-icon-menu','el-icon-share','el-icon-coin','el-icon-place','el-icon-s-custom',
                'icon icon-ai','icon icon-shandian','icon icon-diannengtongji','icon icon-dianxiangguanli',
                'icon icon-changsuoguanli','icon icon-gongdan','icon icon-kaoqin','icon icon-home','icon icon-role','icon icon-depart',
                'icon icon-renyuan1','icon icon-renyuan2','icon icon-pingtaixinxi','icon icon-safety','icon icon-place',
                'icon icon-shebeiguanli','icon icon-lishigaojing','icon icon-gaojingguanli','icon icon-jiankong','el-icon-s-claim',
                'icon icon-timeplan','el-icon-date','icon icon-shujutongji','icon icon-chuangan','el-icon-s-order','icon icon-xunjianguanli',
                'icon icon-renwu','icon icon-zichan','icon icon-fenlei','icon icon-xitongyunwei'],
      //tree配置项
      cascProps :{
        label: 'menuName',
        children: 'childPageMenus'
      },

      rules :{
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
        menuCode: [
          { required: true, message: '请输入菜单编码', trigger: 'blur' },
          // { min:4, max:32, message: '只能输入4-32位菜单编码', trigger: 'blur' },
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods:{
    //初始化
    async init (type,item){
      // console.log(item);
      await vm.getParentList()
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '添加菜单'
        }else if(type == 'edit'){
          vm.dioTit = '编辑菜单'

          vm.inform.id = item.id
          vm.inform.appId = item.appId
          vm.inform.menuCode=item.menuCode//编码
          vm.inform.menuName=item.menuName//名称
          vm.inform.parentId=item.parentId//父级ID
          vm.inform.menuType=item.menuType//类型：DIR目录，PAGE菜单，BUTTON按钮
          vm.inform.menuUrl=item.menuUrl//菜单地址
          vm.inform.idx=item.idx//排序号
          vm.inform.iconUrl=item.iconUrl//图标

          vm.treeCurrNode()
        }
      })
    },
    //获取上级菜单列表
    async getParentList (){
      let dataObj = {
        'appCode': 'APP_SECURITY_PC',//应用编码
      }
      let res = await apiUrl.getPageMenuTreeByAppCode(qs.stringify(dataObj))
      if(res?.code == 200){
        // let arr = []
        // for(let i in res.data){
        //   if(res.data.menuType != 'BUTTON'){
        //     arr.push(res.data[i])
        //   }
        // }
        vm.menuList[0].childPageMenus = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    // 菜单树选中
    treeChange (data, node){
      vm.inform.parentId = data.id
      vm.inform.parentName = data.menuName
    },
    // 菜单树设置当前选中节点
    treeCurrNode (){
      vm.$refs.menuListTree.setCurrentKey(vm.inform.parentId)
      vm.inform.parentName = (vm.$refs.menuListTree.getCurrentNode() || {})['menuName']
    },
    //选择图标
    checkIcon (item){
      vm.inform.iconUrl = item
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addMenuInsert()
          }else if(vm.dioType=='edit'){
            vm.updateMenu()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio(formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.inform.id = ''
      vm.inform.parentId = ''
      vm.$refs.menuListTree.setCurrentKey('')
      vm.isDio = false
    },
    
    //新增
    async addMenuInsert (){
      let dataObj = {
        'menuCode': vm.inform.menuCode,//编码
        'menuName': vm.inform.menuName,//名称
        'parentId': vm.inform.parentId,//父级ID
        'menuType': vm.inform.menuType,//类型：DIR目录，PAGE菜单，BUTTON按钮
        'menuUrl': vm.inform.menuUrl,//菜单地址
        'idx': vm.inform.idx,//排序号
        'iconUrl': vm.inform.iconUrl,//图标
      }
      const res = await apiUrl.addMenuInsert(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateMenu (){
      let dataObj = {
        'id':vm.inform.id,
        'appId':vm.inform.appId,
        'menuCode': vm.inform.menuCode,//编码
        'menuName': vm.inform.menuName,//名称
        'parentId': vm.inform.parentId,//父级ID
        'menuType': vm.inform.menuType,//类型：DIR目录，PAGE菜单，BUTTON按钮
        'menuUrl': vm.inform.menuUrl,//菜单地址
        'idx': vm.inform.idx,//排序号
        'iconUrl': vm.inform.iconUrl,//图标
      }
      const res = await apiUrl.updateMenu(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.parentPop{
  .el-tree{
    max-height: 300px;
    overflow: auto;
    .el-tree-node__content:hover,.el-tree-node.is-current > .el-tree-node__content{
      background-color: #16b7ff;
    }
    .el-tree-node:focus>.el-tree-node__content{
      background-color: #16b7ff;
    }
  }
}
.dutyAddEdit{
  .menu-list__input,
    .icon-list__input {
      > .el-input__inner {
      cursor: pointer;       
    }
  }
  .formItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      flex: 1;
    }
    .showIcon{
      flex-shrink: 0;
      margin-left: 10px;
      color: #E6A23C;
    }
  }
}
.popMenu{
  .menu_iconDiv{
    max-height: 300px;
    overflow: auto;
    .el-button--small{
      padding: 5px 10px;
      margin: 0 5px 5px;
      font-size: 16px;
      &.active{
        border-color: #409EFF;
        color: #409EFF;
      }
    }
  }
}

</style>