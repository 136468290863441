<template>
  <div class='system-menu mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:system:menu:add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
          <el-button type="text" @click="openDio('sort')" v-if="isAuth('safe:system:menu:sort')"><i class="el-icon-sort"></i>同级排序</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table 
          class="tableCont" 
          :data="tableData" 
          row-key="id" 
          :tree-props="{ children: 'childPageMenus', hasChildren: 'hasChildren' }"
          stripe 
          :height="tableH"
          highlight-current-row
          @row-click="tableClick"
          :cell-class-name="cellClass"
          style="width:100%">
          <el-table-column prop="menuName" label="名称"/>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.menuType=='DIR'" class="primary">目录</span>
              <span v-else-if="scope.row.menuType=='PAGE'" class="success">菜单</span>
              <span v-else class="warning">按钮</span>
            </template>
          </el-table-column>
          <el-table-column label="图标">
            <template slot-scope="scope">
              <i :class="scope.row.iconUrl" style="font-size:20px;"></i>
              <!-- <i class="icon" :class="scope.row.iconUrl" v-if="scope.row.iconUrl?.includes('icon-')"></i>
              <el-icon :size="20" v-if="scope.row.iconUrl && scope.row.iconUrl?.includes('icon-') == false">
                <component :is="scope.row.iconUrl"></component>
              </el-icon> -->
            </template>
          </el-table-column>
          <el-table-column prop="idx" label="排序号"/>
          <el-table-column prop="menuUrl" label="菜单URL"/>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)" v-if="isAuth('safe:system:menu:edit')"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:system:menu:del')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--创建、修改-->
    <menu-add-edit ref="editDio" @addEditSucc="getList()"></menu-add-edit>

    <!--菜单排序-->
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDio tableDivPage sortDio">
        <el-table class="tableCont" stripe ref="tablesort" :data="sortData" style="width:100%;" height="400" center row-key="id" highlight-current-row>
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column label="菜单名称" prop="menuName" align="center"></el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <el-button size="small" @click="closeDio()">关闭</el-button>
        <el-button size="small" type="primary" @click="saveSort()">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import Sortable from "sortablejs"
import menuAddEdit from './menu-add-edit.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
var vm;
export default {
  name:'system-menu',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
      },
      
      tableActive:[],
      
      tableH :'',//表格高度
      tableData :[],

      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度
      sortData :[],
    }
  },
  components:{
    menuAddEdit,
    breadCrumb,
  },
  created() {
    vm = this;
    vm.initHeight()
  },

  mounted(){
    vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //表格单击行
    tableClick (row, column, event){
      if(row.childPageMenus){
        let arr = []
        for(var i in row.childPageMenus){
          let obj = {
            id:row.childPageMenus[i].id,
            menuName:row.childPageMenus[i].menuName,
          }
          arr.push(obj)
        }
        vm.tableActive = arr
      }else{
        vm.tableActive = []
      }
    },
    //设置table某列的class
    cellClass({ row, column, rowIndex, columnIndex }){
      if (columnIndex === 0) {
        return 'leftCol'
      } 
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteMenu(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增或修改
        }else if(type=='sort'){
          if(vm.tableActive.length==0){
            let arr=[]
            for(var i in vm.tableData){
              let obj = {
                id:vm.tableData[i].id,
                menuName:vm.tableData[i].menuName,
              }
              if(vm.tableData[i].parentId=='0'){
                arr.push(obj)
              }
            }
            vm.sortData = JSON.parse(JSON.stringify(arr))
          }else{
            vm.sortData = JSON.parse(JSON.stringify(vm.tableActive))
          }
          vm.dioType = type
          vm.dioTit = '同级资源排序'
          vm.dioWidth = '330px'
          vm.isDio = true
          //使每次都可以拖拽
          vm.$nextTick(()=>{
            setTimeout(()=>{
              vm.rowDrop();
            },100)
          })
        }
      }
    },
    //删除
    async deleteMenu (ids){
      // console.log(ids);
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteMenu(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    // 行拖拽
    rowDrop (){
      const tbody = vm.$refs.tablesort.$el.querySelector('.el-table__body-wrapper tbody');
      Sortable.create(tbody, {
        draggable: ".el-table__row",
        onEnd: ({ newIndex, oldIndex }) => {
          const currRow = vm.sortData.slice(oldIndex)[0]
          vm.sortData.splice(oldIndex, 1);
          vm.sortData.splice(newIndex, 0, currRow);
        }
      })
    },
    //保存排序
    saveSort (){
      let arr = vm.sortData
      for(var i in arr){
        arr[i].reorder = parseInt(i)+1
      }
      vm.menuSort()
    },
    //保存同级排序
    async menuSort (){
      let arr = []
      for(var i in vm.sortData){
        let obj = {
          id:vm.sortData[i].id,
          idx:parseInt(i)+1
        }
        arr.push(obj)
      }
      const res = await apiUrl.batchUpdateIdx(arr)
      if(res?.code==200){
        vm.$message.success('保存成功')
        vm.getList()
        vm.closeDio();//关闭弹窗
      }else{
        vm.$message.error(res.message)
      }
      
    },
    //关闭弹窗
    closeDio (){
      vm.sortData = []
      vm.isDio = false
    },
    //获取列表
    async getList (){
      let dataObj = {
        'appCode': 'APP_SECURITY_PC',//应用编码
      }
      const res = await apiUrl.getPageMenuTreeByAppCode(qs.stringify(dataObj))
      if(res?.code==200){
        // ElMessage.success('登录成功')
        vm.tableData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-172
    },
  },
}
</script>
<style lang='scss'>
.system-menu{
  .el-table{
    .leftCol{
      text-align: left;
      padding: 5px 9px;
      .el-icon{
        color: #fff;
      }
    }
  }
  .sortDio{
    .el-table__body tr{
      cursor: move;
    }
  }
}
</style>
